* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #1e1d1f !important;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* For MUI TextField */
.headerInput input {
    padding-top: 4px;
    font-size: 1rem;
    font-weight: 500;
}

/* GSDevTools Timeline */
.gs-dev-tools {
    position: relative !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.gs-dev-tools .playhead {
    border: none !important;
    background-color: #F44336 /*#01b3db*/ !important;
}

.gs-dev-tools .in-point-shape {
    fill: #636363 /*#01b3db*/ !important;
}

.gs-dev-tools .out-point-shape {
    fill: #636363 !important;
}

.gs-dev-tools .timeline-track {
    background-color: #636363 !important;
}

.gs-dev-tools .progress-bar {
    background-color: #636363 /*#01b3db*/ !important;
    opacity: 0.6 !important;
}

.gs-dev-tools .logo,
.gs-dev-tools .select-animation,
.gs-dev-tools .time-scale,
.gs-dev-tools .loop {
    display: none !important;
}
.gs-dev-tools .rewind {
    display: none !important;
}

.gs-dev-tools .select-animation-container {
    width: 0 !important;
}

.gs-dev-tools .gs-top {
    /* padding-top: 0px !important; */
    padding-bottom: 10px !important;
}

.gs-dev-tools .gs-bottom {
    background-color: #1e1d1f !important;
    border-top: none !important;
    padding-bottom: 0px !important;
}

.gs-dev-tools .gs-bottom-right {
    width: 0 !important;
    flex-grow: 2 !important;
}

.gs-dev-tools .rewind,
.gs-dev-tools .play-pause,
.gs-dev-tools .loop {
    width: 10px !important;
    min-width: 10px !important;
    z-index: 100;
}

.gs-dev-tools .time-scale {
    padding-top: 0 !important;
}

.gs-dev-tools .time-scale-label,
.gs-dev-tools .time-container {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    font-weight: 500 !important;
    font-size: 0.8rem !important;
    color: white !important;
}

/* React splitters */
.custom-gutter-horizontal,
.custom-gutter-vertical {
    background-color: #272629;
}

.custom-gutter-horizontal > div,
.custom-gutter-vertical > div {
    background-color: #272629;
}

.custom-gutter-horizontal:hover {
    cursor: col-resize;
}

.custom-gutter-vertical:hover {
    cursor: row-resize;
}

.not-draggable:hover,
.not-draggable:hover {
    cursor: default;
}

/* React-color: Chrome picker */
.chrome-picker {
    background-color: #313033 !important;
    border-radius: 6px !important;
    box-shadow: none !important;
    margin: 3px !important;
}

.chrome-picker svg {
    fill: rgb(150, 150, 150) !important;
}
